import React from "react";
import { graphql } from 'gatsby'
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import BreadCrumbs from '../Components/PropertyDetails/BackButton/BreadCrumbs';
import SEO from '../Components/Seo/seo';
import CalculatorData from "../Components/CalculatorDetails/CalculatorData";

const CalculatorDetails = (props) => {
    const GQLPage = props.data.glstrapi?.calculator;
    return (
        <React.Fragment>
            <div className="wrapper calculator-details-page">
                <div className="inner-header"><Header /></div>
                <SEO title={GQLPage?.Meta_Title ? GQLPage?.Meta_Title : ''} description={GQLPage?.Meta_Description ? GQLPage?.Meta_Description : ''} />
                <BreadCrumbs alias="calculators" baselabel="Calculators" baseurl={"/find-a-mortgage/calculators/"} itemlabel={GQLPage?.Name} />
                <CalculatorData calculatordata={GQLPage} />
                <Footer popularSearch="Popular_Search_Static_Common" />
            </div>
        </React.Fragment>
    )
}
export default CalculatorDetails;
export const calculatorQuery = graphql`
query CalculatorQuery($id: ID!) {
  glstrapi {
        calculator(id: $id) {    
            id
            Name
            URL
            Meta_Title
            Meta_Description
            StraplineText
            IframeUrl
            Content
            ShowImage
            Image {
                url
                alternativeText
                url_sharp {
                    childImageSharp {
                        gatsbyImageData(formats: AUTO
                            width: 800
                            quality: 100
                            layout: FIXED
                            transformOptions: {cropFocus: CENTER, fit: COVER}
                        )    
                    }
                }
            }
        }
    }
}`
