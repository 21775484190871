import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import { getSrc } from "gatsby-plugin-image"
import { AltSiteName } from "../utils";
import "./CalculatorData.scss";

const CalculatorData = (props) => {
    const calculatorData = props?.calculatordata;

    return(
       <div className="calculator-data-wrap">
        <Container>        
            <Row>
               <Col md="12" lg={{span: 7}}>
                <div className="content-wrap">
                    {calculatorData?.Name && <h1>{calculatorData?.Name}</h1>}
                    {calculatorData?.Content &&
                        <div className="content">
                            {parse(calculatorData?.Content)}
                        </div>
                    }
                </div>
               </Col>
               {calculatorData?.ShowImage && calculatorData?.Image?.url ? (
                    <Col md="12" lg="5">
                        <div className="content-sidebar">                    
                            <img 
                                src={getSrc(calculatorData.Image.url_sharp)} 
                                alt={calculatorData?.Image?.alternativeText || calculatorData?.Name + AltSiteName} 
                            />
                        </div>
                    </Col>
                ) : calculatorData?.IframeUrl ? (
                    <Col md="12" lg={{ span: 4, offset: 1 }}>
                        <div className="content-sidebar">
                            <iframe
                                src={calculatorData.IframeUrl}
                                style={{ width: "100%", height: "100vh", border: "none" }}
                                title="Calculator"
                                loading="lazy"
                                onError={(e) => e.target.style.display = 'none'}
                            ></iframe>
                        </div>
                    </Col>
                ) : null}
            </Row>
        </Container>
       </div>
    )
}
export default CalculatorData;